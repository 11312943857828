import "./tool-variables.scss";

import "./tool-page.scss";
import "./tool-intro.scss";

import "./tool-buttons.scss";
import "@mod-kenjebankzaken/webdesigns/shared/tool-questions.scss";
import "@mod-kenjebankzaken/webdesigns/shared/tool-results-effectgroups.scss";
import "@mod-kenjebankzaken/webdesigns/shared/tool-results-sharebar.scss";
import "./tool-results-extras.scss";

import "./tool-popup.scss";
import "./tool-reminder.scss";

import "./tool-infobutton.scss";

//import * as dialogapi from 'dompack/api/dialog';
import * as dompack from "dompack";
import * as effecttool from "@mod-toolplatform/tools/effecttool";
import * as whintegration from "@mod-system/js/wh/integration";
import "../../../shared/wh.social.sharepage.es";
//import JSONRPC from "@mod-system/js/net/jsonrpc";
// import setupToggle from "../../../shared/components/toggle";

import * as drawingTools from "../../shared/drawingtools.es";


import * as toolhelpers from "@mod-kenjebankzaken/webdesigns/shared/effectgroups-as-accordeon.es";

import tippy from 'tippy.js';
// import tippy, {roundArrow} from 'tippy.js';
//import tippy, {followCursor} from 'tippy.js/headless';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';


// window.setInterval(function() {console.log(document.activeElement); }, 500);
window.whint = whintegration; // debug





let debugmode;
if (location.href.indexOf("debug") > -1)
  debugmode = true;


// Nodes
let toolnode;

const tellen = ["", "één", "twee", "drie", "vier", "vijf", "zes", "zeven", "acht", "negen", "tien", "elf", "twaalf", "dertien", "veertien", "vijftien", "zestien", "zeventien", "achtien", "negentien", "twintig"];

let prefilllink = "";

function earlyInit()
{
  tippy.setDefaultProps(
      { arrow:  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 32 16"><path d="M16 0l16 16H0z"></path></svg>' });
}


function initToolExtras(node)
{
  console.info('INIT "Ken je bankzaken" tool extras');

  if (whintegration.config.dtapstage == "production")
    effecttool.setupGA("G-D85C6ZD218", "Ken je bankzaken");
  else
    effecttool.setupGA("G-B257TNS765", "Ken je bankzaken");

  let startbuttons = document.querySelectorAll(".tool__startbutton");
  for (let node of startbuttons)
    node.addEventListener("click", doStartTool);


  //alert("100vh" + document.body.height + ", real height " + window.innerHeight);

  window.addEventListener("resize", updateRealVH);
  updateRealVH();


  if (debugmode)
    document.documentElement.classList.add("debug-tool");

  toolnode = node;

  resetProgressBar(); // FIXME: we don't receive the first question event??

  // Listen for the tool to be finished. When it shows the resultpage we go to page 1b (our custom resultpage) and enable the button to page 2
  node.addEventListener("toolplatform:progress", onToolPageSwitch);
  node.addEventListener("toolplatform:resultspage", onToolResultsPage);



  rewriteDOM(node);



  let copylinkbutton = document.querySelector(".customtool__copyprefilllink");
  if (copylinkbutton)
    copylinkbutton.addEventListener("click", doCopyPrefillLinkToClipboard);

  let restartbutton = document.querySelector(".customtool__restart");
  if (restartbutton)
    restartbutton.addEventListener("click", doRestart);
/*
  document.querySelector(".toolinfo__page2__footerlinks").addEventListener("click", doCheckForPageClick);

  document.querySelector(".customtool__infoscreen").addEventListener("click", doCheckForBacklink);
*/
  moveInfoButtonsToQuestionHeader(); // handle the question info buttons
  // tippy('[data-tippy-content]'); // handle custom info buttons in the witty

  prefillAnswersFromURL();
}



function rewriteDOM(node)
{
  //initBackground();

  initStartPage();

  initResultsHeader();

  toolhelpers.morphEffectGroupsIntoAccordeon(node);
}


function initStartPage()
{
  // Add the start button to the intro screen
  let startpage = document.querySelector(".effecttool__opening");
  if (startpage)
  {
    let startpage_bg = drawingTools.drawSlopedPanel(startpage);

    let buttonbar =
        <div class="kjb-startbuttonbar">
        </div>;

    let startbutton1 =
        <button class="kjb-startbutton"
                type="button"
                aria-label="Start"
                >
          Start nu!
        </button>;
    startbutton1.addEventListener("click", startTheTool);

    let startbutton2 =
        <button class="kjb-startbutton-star"
                type="button"
                aria-label="Start"
                aria-hidden="true"
                >
        </button>;
    startbutton2.addEventListener("click", startTheTool);

    buttonbar.appendChild(startbutton1);
    buttonbar.appendChild(startbutton2);
    startpage.appendChild(buttonbar);
  }
}

function startTheTool()
{
  window.__toolcontroller._gotoFirstQuestion();
}



function updateRealVH()
{
  document.documentElement.style.setProperty("--realvh", window.innerHeight + "px");
}

function doStartTool()
{
  toolnode.classList.add("showtool");
}



function resetProgressBar()
{
  let questions = document.querySelectorAll(".effecttool__question").length;
  setProgress(1, questions);
}

function doRestart()
{
  /*
  NOTE: this version without reload doesn't work correctly yet.. After the first question it jumps to the results.

  window.__toolcontroller.goToQuestionById(window.__toolcontroller.getQuestionIdByNr(0));
  resetProgressBar();
  toolnode.classList.remove("showtool");
  */

  window.addEventListener("beforeunload", () => window.scrollTo(0,0));
  location.reload();
}


// NOTE: ToolPlatform offers window.getToolPrefillURL, but it doersn't work for checkbox questions
function generateURLFromAnswers(answers)
{
  // answers
  //   .uid
  //   .value[]
  let panswers = [];
  for (let answer of answers)
  {
    // console.log("answer", answer);
    panswers.push(answer.uid + "=" + encodeURI( answer.value.join(",") ));
  }
  let query = panswers.join("&");

  let finalurl = location.origin + location.pathname + "?prefill&" + query;

  //console.log(finalurl);

  return finalurl;
}


function doCopyPrefillLinkToClipboard()
{
  navigator.clipboard.writeText(prefilllink);
}



function prefillAnswersFromURL()
{
  console.info("prefillAnswersFromURL");

  let urlParams = new URLSearchParams(window.location.search);

  if (!urlParams.has("prefill"))
    return;

  window.__toolcontroller._gotoFirstQuestion();

  for (const [key, detokenizedvalue] of urlParams.entries())
  {
    let values = detokenizedvalue.split(",");

    for (const value of values)
    {
        console.log(key, value);

        // For check name={guid}
        // For radiobutton name=answer-{guid}

        let node = document.querySelector(`[name^="${key}"][value="${value}"]`);
        if (node)
        {
          node.checked = true; // check the checkbox
          node.setAttribute("checked", true);
          // console.log("SELECTED", node);
          continue;
        }

        node = document.querySelector(`[name^="answer-${key}"][value="${value}"]`);
        if (node)
        {
          node.checked = true;
          node.setAttribute("checked", true);
          // console.log("SELECTED", node);
          continue;
        }

        //console.log("Node", node);
        console.log("Failed to find", key, "value", value);
    }
  }


  let breakin = 99;
  while(true) // do...while
  {
    let result = window.__toolcontroller.doNext();
    console.log(result);
    if (!result.success || result.page == "results")
      break;

    breakin--;
    if (breakin === 0)
    {
      console.error("FAIL");
      break;
    }
  }

  doStartTool();


  /*

  Selector to find the checkbuttons
  [name^="{questionguid}"][value="{answerguid}"]

  Checkbox
  <input type="checkbox" name="vg1RXa6bSAUVkstyaUlCAQ" value="Z-aWgErRYJi3JGZD3vwLvg" id="answer-Z-aWgErRYJi3JGZD3vwLvg" data-uid="Z-aWgErRYJi3JGZD3vwLvg" data-points="0" autocomplete="off">


  Selector to find the radiobuttons
  [name^="answer-{questionguid}"][value="{answerguid}"]

  Radiobutton
  <input type="radio" name="answer-SUY8GCyVcklX9shNbLojpg"
         value="gg4qgq2DLyGI5cJUYz0ufQ"
         id="answer-SUY8GCyVcklX9shNbLojpg-1" data-points="0" autocomplete="off">

  */
}



/////////////////////////////////////////////////////////////////////
//
//  Vragen pagina's


// Move the info in the DOM and attach Tippy so the info appears as tooltip
function moveInfoButtonsToQuestionHeader()
{
  let questionnodes = toolnode.querySelectorAll(".effecttool__question");

  for (let questionnode of questionnodes)
  {
    let moreinfo = questionnode.querySelector(".effecttool__moreinfo");
    if (!moreinfo)
      continue;

    let new_info = getInfoButton(); //moreinfo.innerHTML);

    // new_info.querySelector(".avtool__infobutton__popup").appendChild(moreinfo);
    tippy( new_info.querySelector("button")
         , { content: moreinfo.innerHTML
           , allowHTML: true
         //, hideOnClick: false // set to false for testing
           , trigger: 'mouseenter click'
           // , arrow: roundArrow
           ,
           });
    moreinfo.parentNode.removeChild(moreinfo); // remove the original moreinfo node


    let questiontext_node = questionnode.querySelector(".effectool__questiontext");

/*
    let questiontext_h2 = questiontext_node.querySelector("h2");

    if (questiontext_h2)
      questiontext_h2.appendChild(new_info);
    else
      questiontext_node.appendChild(new_info);
*/
    questiontext_node.insertBefore(new_info, questiontext_node.firstChild);
  }
}

function getInfoButton()
{
  return <div class="avtool__infobutton">
           <button class="avtool__infobutton__button" tabindex="0"></button>
         </div>;
}

function onToolPageSwitch(evt)
{
  /*
  NOTES:
  - at this point .effecttool__page--visible is still the page from before .nextPage() was called
  - window.__toolcontroller contains the ToolController instance (we assume to only have one tool on the page)
  */

  console.log("toolplatform:progress", evt.detail);

  setProgress(evt.detail.numquestions - evt.detail.questionsleft + 1, evt.detail.numquestions);

  let nextpage = evt.detail.nextpage;
  if (!nextpage.question) // not a question page (probably the resultpage)
    return;
}


function setProgress(current, total)
{
  // console.info("setProgress", current, total);

  let container = document.querySelector(".qpanel__progressbullets");
  // if (currentnode)
    // currentnode.remove();
  container.innerHTML = "";

  if (current == total + 1)
  {
    // We are at the results page
    return;
  }

  //let container = document.createElement("div");
  container.classList.add("customtool__progress");


  let xmlns = "http://www.w3.org/2000/svg";

  let xpos = 0; //76;
  for (let page = 1; page <= total; page++)
  {
    let item = document.createElementNS(xmlns, "circle");
    item.setAttribute("cx", xpos);
    item.setAttribute("cy", 0); //"86.5");
    item.setAttribute("r", "4.5");

    if (page == current)
      item.setAttribute("class", "active");

    xpos += 15;

//    item.className = page == current ? "customtool__progress__item customtool__progress__item--selected" : "customtool__progress__item"
    container.appendChild(item);
  }

  //let pagescontainer = document.querySelector("#effecttool__pages");
  //pagescontainer.insertBefore(container, pagescontainer.firstChild);

  let questionr_container = document.querySelector(".qpanel__questionnumber__text");
  questionr_container.innerHTML = current;
}


/////////////////////////////////////////////////////////////////////
//
//  Resultaten pagina

function initResultsHeader()
{
  let results_header = document.querySelector(".effecttool__results__header");

  // If the results start with a H2, upgrade it to a special highlighted header
  let firsth2 = document.querySelector(".effecttool__results__header h2:first-child");
  let title = "";
  if (firsth2)
  {
    title = firsth2.innerText;

    let newheading =
          <div class="effecttool__results__header__h2">
            <img src={whint.config.imgroot+"button-red-full.svg"} />
            <div class="effecttool__results__header__h2__title">{title}</div>
          </div>

    firsth2.parentNode.replaceChild(newheading, firsth2);
  }

  let results_header_bg = drawingTools.drawSlopedPanel(results_header);
}

function getScoreWrapper(points)
{
  if (points >= 40)
  {
    return <div class="kenjebankzaken-score">
             <div class="kenjebankzaken-score__symbol" aria-hidden="true">
               {/*<span class="far fa-smile-beam"></span>*/}
               <span class="fas fa-circle"></span>
               <span class="far fa-grin"></span>
             </div>
             <div class="kenjebankzaken-score__text">
               {points} van de <span class="kenjebankzaken-score__points">50</span> punten.<br />
               Goed gedaan!
             </div>
           </div>;
  }
  else if (points >= 30)
  {
    return <div class="kenjebankzaken-score">
             <div class="kenjebankzaken-score__symbol">
               <span class="fas fa-circle"></span>
               <span class="far fa-meh"></span>
             </div>
             <div class="kenjebankzaken-score__text">
               {points} van de <span class="kenjebankzaken-score__points">50</span> punten.<br />
             </div>
          </div>;
  }
  else
  {
    return <div class="kenjebankzaken-score">
             <div class="kenjebankzaken-score__symbol">
               <span class="fas fa-circle"></span>
               <span class="far fa-sad-tear"></span>
             </div>
             <div class="kenjebankzaken-score__text">
               {points} van de <span class="kenjebankzaken-score__points">50</span> punten.<br />
            </div>
           </div>;
  }
}

function onToolResultsPage(evt)
{
  console.group("onToolResultsPage");
  console.log("[p3dtool] Results page", evt.detail);


document.documentElement.classList.add("onresultspage");

  let resultsheader = document.querySelector(".effecttool__results__header");

  let resultsheader_h2 = document.querySelector(".effecttool__results__header__h2");

  let scorewrapper = getScoreWrapper(evt.detail.points);
  //resultsheader.appendChild(scorewrapper);
  resultsheader.insertBefore(scorewrapper, resultsheader_h2.nextSibling ?? resultsheader.firstChild);


//  if (evt.detail.points)

/*
  let bdate_answer = results.answers.find(function(answer) { return answer.question.toLowerCase() == "geboortedatum"; });
  let prefilllink = generateURLFromAnswers(evt.detail.answers);
*/


console.log(evt.detail);

  //console.log("Answers", evt.detail.answers);

/*
{
    "numquestions": 9,
    "type": "results",
    "variants": [
        "08rqO9bun4qHS9dV5jkhoA",
        "S1zV75fcDv6g5tLQhJXXFA",
        "XwLIeOpwxqCJqDT_HsLkFw",
        "V7MGuMzKpJDwIVGKxCwozQ",
        "pmZ7amiPwpe2rf09uy3n5w",
        "kzwJ468hs5hoqMUH5im1_g",
        "pMpXPtcJruWvptqlBsVv8A",
        "-LQ-tL1CL1aPHEDK_J02Lg",
        "eqkwZ7GzUfJm6uy1Io3eXA"
    ],
    "answers": [
        {
            "uid": "vg1RXa6bSAUVkstyaUlCAQ",
            "type": "radio",
            "points": 0,
            "value": [
                "6Jus85VysxGA_Ay2Ipud3A"
            ],
            "question": "Telefoontje bank",
            "answertext": "Ik ga in gesprek. Ik wil weten waarvoor ze bellen.",
            "used": true
        },
        {
            "uid": "baSicoN-tHUoUoZxY6DIfw",
            "type": "radio",
            "points": 0,
            "value": [
                "ThCTqlmsSFsytm63-vYUdg"
            ],
            "question": "Verzoek van een vriend",
            "answertext": "Ik weet het niet.",
            "used": true
        },
        {
            "uid": "oAl_H9sjXT-xaiJrvVR7Mw",
            "type": "checkboxlist",
            "points": 0,
            "value": [
                "1Zj9LYC9qHp3sKaBtMF2WA"
            ],
            "question": "Mailtje bank",
            "answertext": "Ik kijk naar het e-mailadres.",
            "used": true
        },
        {
            "uid": "LwrVfkM0V5CiyrZYYBbcsg",
            "type": "radio",
            "points": 0,
            "value": [
                "iC2ktAocAePi5Y-Vir4f7g"
            ],
            "question": "Nieuwe pinpas",
            "answertext": "Die gooi ik weg.",
            "used": true
        },
        {
            "uid": "lDmAR9fiMcVbOG5QuLIMmA",
            "type": "checkboxlist",
            "points": 0,
            "value": [
                "NQ3yuw-FMrkhJcCb4tT7HA"
            ],
            "question": "Inlogcodes",
            "answertext": "Ik ken ze uit m'n hoofd.",
            "used": true
        },
        {
            "uid": "b0kXWShirZoTRejPOutfLQ",
            "type": "checkboxlist",
            "points": 0,
            "value": [
                "UAKpspAz6z_BLbU5_TR35w"
            ],
            "question": "Veilig pinnen",
            "answertext": "Shouldering.",
            "used": true
        },
        {
            "uid": "UmuJK0bstHnhXHUbQ5v-fw",
            "type": "radio",
            "points": 0,
            "value": [
                "NUxpJ38oS-GaDqkO1NjOhA"
            ],
            "question": "Hoe vaak check jij je bankrekening?",
            "answertext": "Een keer per dag",
            "used": true
        },
        {
            "uid": "qfI6JZAgdiE7wpH9XQ6gTg",
            "type": "checkboxlist",
            "points": 0,
            "value": [
                "ypQRQFl0wTPm5o4Gvoe5Jg"
            ],
            "question": "Beveiligen smartphone",
            "answertext": "Ik download updates.",
            "used": true
        },
        {
            "uid": "LJfpZKoJKDD5o95sv5uF4Q",
            "type": "radio",
            "points": 0,
            "value": [
                "9ZhCE5HdyauZiZqzuncGqw"
            ],
            "question": "Met geld omgaan",
            "answertext": "Ik praat met mijn ouders over geld.",
            "used": true
        }
    ],
    "questionsleft": 0,
    "points": 0
}
*/

  let results = evt.detail;
  window.toolresult = results;

  // let results = getToolResults(); // gives back some more info than passed in evt.details (points and measuregroups) - NOT EXPOSED
  console.log("getToolResults", results);
  console.groupEnd();

 // morphEffectsIntoAccordeon();

  openFirstVisibleEffectGroup();

  // makePartOfEffectsGroupTitleBold();

  updateEffectsCountText(evt);

  addOddEvenClassnamesToEffects();

  prefilllink = generateURLFromAnswers(evt.detail.answers);


  //if (whintegration.config.obj.mailresultstext)
  //  finalurl += "&" + encodeURI(whintegration.config.obj.mailresultstext);

  // Update mailto: URL in button
  let subject = "Ken je bankzaken";
  let body = whintegration.config.obj.mailresultstext + "\n\n" + prefilllink;
  let anchorbutton = document.querySelector(".customtool__mailprefilllink");
  anchorbutton.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
}

function openFirstVisibleEffectGroup()
{
  let effectgroups = document.querySelectorAll(".effecttool__group");

  idx = 0;
  for(let node of effectgroups)
  {
    if (node.hasAttribute("hidden"))
      continue;

    if (idx == 0) // first visible effectgroup ?
      node.setAttribute("open", "");

    idx++;
  }
}

/*
function makePartOfEffectsGroupTitleBold()
{
  dompack.qSA(".effecttool__group__title").forEach(node =>
  {
    let parts = node.textContent.split("moet");
    if(parts.length == 2) //replace it!
    {
      node.textContent = "";
      node.append(parts[0],<strong>moet</strong>,parts[1]);
    }
  });
}
*/

function updateEffectsCountText(evt)
{
  let effectscount = evt.detail.variants.length;
  // let effectstext = (effectscount > 1 ? "zijn " : "is ") + tellen[effectscount];
  let effectstext = tellen[effectscount];
  //document.querySelector(".effectscount").innerText = (effectscount > 1 ? "zijn " : "is ") + tellen[effectscount];

  dompack.qSA(".effecttool__results__header *").forEach(node =>
  {
    for(let subnode = node.firstChild; subnode; subnode = subnode.nextSibling)
      if(subnode.childNodes.length == 1 && subnode.firstChild.nodeType == 3) //contains only textnode
      {
        let parts = subnode.textContent.split("[aantal]");
        if(parts.length == 2) //replace it!
        {
          subnode.textContent = "";
          subnode.append(parts[0],<span class="effectscount">{effectstext}</span>,parts[1]);
        }
      }
  });
}

function addOddEvenClassnamesToEffects()
{
  let effectgroups = toolnode.querySelectorAll(".effecttool__group");
  for (let group of effectgroups)
  {
    let effects = group.querySelectorAll(".effecttool__effect");

    let idx = 0;
    for(let effect of effects)
    {
      if (effect.hasAttribute("hidden"))
        continue;

      idx++;
      effect.classList.toggle("effecttool__effect__odd",  idx % 2 == 1);
      effect.classList.toggle("effecttool__effect__even", idx % 2 == 0);
    }
  }
}



earlyInit();
dompack.register(".effecttool--kenjebankzaken", initToolExtras);
