import { getUTCDateArray, getGoogleCalendarLink } from './calendarurls.es';

import * as dompack from "dompack";
import * as browser from "dompack/extra/browser";
import * as whintegration from "@mod-system/js/wh/integration"; //initializes debugflags
import KeyboardHandler from "dompack/extra/keyboard";

import '../css/formlayout.scss';

import ToolController from './toolcontroller.es';

import * as dialogapi from 'dompack/api/dialog';

var toolcontroller = null;


/* URL options:

   - arguments for embedding

       - iframeautoresize
         (for use in the FGM site)
         When used "iframeresizer" will communicate with the site which embed us to autoresize the iframe

   - arguments for testing

       - mobile=yes

   - arguments used by backend

       - concept=1


   Setup:

   - every subject (grouped questions) is a <div class="questiongroup">.
     Each subject contains 1 or more questions.
     These question groups have a horizontal animation.
     The current question group has class "active"

   - every question resides in a <div class="question">.
     Visible question has class "visible".
     If navigating in the same question group, we have a vertical animation.

   - resultspage: <div id="results">

*/

var rpc;

var reminderpopup = null;

var whconfig = null;
var formsettings = null;

var testmode = dompack.debugflags["tools-testmode"];

var mockanswercookie;
function readAnswersCookie()
{
  return mockanswercookie;
}
function writeAnswersCookie(text,options)
{
  mockanswercookie = text;
}

function checkExternalLink(evt)
{
  let link = evt.target.closest('a[href]');
  if(link && !link.href.startsWith(location.origin))
    link.target = "_blank";
}

function prefillDateOfBirth()
{
  // we can have one or more of the vars 'day', 'month' and 'year' on the URL. If so, prefill the date of birth question and remove saved cookie
  var dobelement = toolcontroller.getDOBElement();
  if (!dobelement)
    return;

  /* Useful test birthdays; ?bd=3277993 1950
                            ?bd=3328639 1980
  */

  let url = new URL(location.href);

  if(url.searchParams.get("birthdate_day")) //long form
  {
    dobelement.querySelector(".date-day").value = url.searchParams.get("birthdate_day");
    dobelement.querySelector(".date-month").value = url.searchParams.get("birthdate_month");
    dobelement.querySelector(".date-year").value = url.searchParams.get("birthdate_year");
    return;
  }

  var bd = url.searchParams.get("bd");
  let parsebd = bd ? bd.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})$/) : null
  if(parsebd)
  {
    dobelement.querySelector(".date-day").value = parsebd[3];
    dobelement.querySelector(".date-month").value = parsebd[2];
    dobelement.querySelector(".date-year").value = parsebd[1];
  }
  else
  {
    var dob_day = bd ? ''+Math.floor(bd%41) : '';
    var dob_month = bd ? ''+Math.floor(bd%(41*41)/41) : '';
    var dob_year = bd ? ''+Math.floor(bd/(41*41)) : '';

    if (dob_day)
      dobelement.querySelector(".date-day").value = dob_day;

    if (dob_month)
      dobelement.querySelector(".date-month").value = dob_month;

    if (dob_year)
      dobelement.querySelector(".date-year").value = dob_year;
  }
}

function prefillOtherQuestions()
{
  //Check for other url params to prefill radio/checkboxes
  const url = new URL(location.href);
  for(const entry of url.searchParams.entries())
  {
    if(!entry[0].startsWith('answer-'))
      continue;

    let inpnode = dompack.qS(`input[name="${entry[0]}"][value="${entry[1]}"]`);
    if(inpnode)
      inpnode.checked = true;
  }
}

//if remote_whconfig is set, we're executed by an embeddeder
window.tools_init = function(node, remote_whconfig)
{
  if (dompack.debugflags["prefill"])
  {
    $$("input").forEach(input =>
    {
      if (input.get("type") == "number")
      {
        input.set("value", input.get("min") ? input.get("min") : 1);
      }
      else if (input.get("type") == "radio")
      {
        input.set("checked","checked");
        input.set("selected","selected");
      }
    });
  }

  // Add site initialization code
  node.addEventListener("click", checkExternalLink);

  var questioncontainers = dompack.qSA(".page.question");

  //Set focus to next date input field if 2 digits with valid value is set and next field is empty
  var datequestiongroups = dompack.qSA('.question[data-type="date"]');
  for( var i = 0; i < datequestiongroups.length; ++i )
  {
    var dateinp = datequestiongroups[i].querySelectorAll('input[class^="date"]');
    for( var d = 0; d < dateinp.length - 1; ++d )//ignore last field
    {
      dateinp[d].addEventListener("keyup", function(ev){
        var nextval = dateinp[this + 1].value;
        if( nextval != "" && 1*nextval > 0)
          return;//if next field has value, don't auto focus

        var strval = dateinp[this].value.replace(/[^0-9]+/g,'');//only numbers
        var ival = 1*strval;
        var min = 1*(dateinp[this].getAttribute("min"));
        var max = 1*(dateinp[this].getAttribute("max"));
        if( ival < min || (max && ival > max) )
          return;//ignore if input is invalid

        if( strval.length >= 2 )
          dateinp[this + 1].focus();
      }.bind(d));
    }
  }



  toolcontroller = new ToolController(node, formsettings);

  //Prefill stuff
  prefillDateOfBirth();
  prefillOtherQuestions();

  var sharetofacebookbutton = document.getElementById("sharefacebook");
  if (sharetofacebookbutton)
    sharetofacebookbutton.addEventListener("click", shareFacebook);

  dompack.qSA(".questioncontainer input").forEach(node => new KeyboardHandler(node, {"enter": () => toolcontroller.doNext() }));

  // set year min &max
  var curyear = (new Date).getFullYear();
  dompack.qSA(".date-year").forEach(node=>node.min = curyear - 100);
  dompack.qSA(".date-year").forEach(node=>node.max = curyear);

  node.classList.add("effecttool--isloaded");

  toolcontroller.startTool();

  window.__toolcontroller = toolcontroller; // FIXME: do we always want to expose this? (we use this now in the P3D tool to be able to call .nextPage() to skip a page with instructions)


  initReminders();
}




function initReminders()
{
  window.addEventListener("click", doCheckForReminder);

  let form = document.querySelector(".effecttool__reminder__form");
  if (form)
    form.addEventListener("change", doUpdateCalendarURL);
}



function doCheckForReminder(evt)
{
  let reminderbutton = dompack.closest(evt.target, ".effecttool__effect__setreminder");
  if (!reminderbutton)
    return;

  let reminder = { title:       reminderbutton.getAttribute("data-reminder-title")
                 , description: reminderbutton.getAttribute("data-reminder-description")
                 , link:        reminderbutton.getAttribute("data-reminder-link")
                 };

  window.showReminderDialog(reminder);
}

window.showReminderDialog = async function(options)
{
  options = { title:       ""
            , description: ""
            , link:        ""
            , location:    ""
            , ...options
            };

  let remindertemplate = document.querySelector(".effecttool__dialog--reminder");
  console.log("Reminder node", remindertemplate);

  // Update the preview
  remindertemplate.querySelector(".effecttool__reminder-title").innerText = options.title;
  remindertemplate.querySelector(".effecttool__reminder-description").innerText = options.description;
  remindertemplate.querySelector(".effecttool__reminder-link").innerText = options.link;

  let form = document.querySelector(".effecttool__reminder__form");
  form.title.value = options.title;
  form.description.value = options.description;
  form.link.value = options.link;

  let reminderdialog = dialogapi.createDialog({ borrow: remindertemplate });
  let selected = await reminderdialog.runModal();
  console.log("Popup returned value", selected);
}

function doUpdateCalendarURL()
{
  let form = document.querySelector(".effecttool__reminder__form");
  let dateval = form.date.value;

  console.log("date", dateval);

  let reminderurl = "";

  // if no date is set or the date is invalid (Chrome returns "" for invalid dates) don't create a reminder URL
  if (dateval != "")
  {
    var calEntry = icsFormatter();
    calEntry.addEvent( form.title.value
                     , form.description.value
                     , "" // no location
                     , new Date(form.date.value).toUTCString()
                     , null
                     );

    reminderurl = calEntry.getDataURL('reminder');
  }

  let downloadlink = document.querySelector(".effecttool__reminder__download");

  if (reminderurl == "")
  {
    downloadlink.removeAttribute("download");
    downloadlink.removeAttribute("href");
  }
  else
  {
    downloadlink.href = reminderurl;
    downloadlink.setAttribute("download", "calendar.ics");
  }
}







function setupEffectTool(node)
{
  dompack.onDomReady( () =>
      {
        //FIXME make a class, encapsulate more stuff...
        formsettings = JSON.parse(node.dataset.tooldata);

        // When we remove this we cannot use "Save Page as"
        //node.removeAttribute("data-tooldata"); // remove for easyer debugging (the attribute fills the whole DevTools/inspect dialog)

        tools_init(node);
      });
}

dompack.register(".effecttool__root", setupEffectTool);
