import * as dompack from "dompack";

/*
<div id="effectgroups" class="effecttool__groups">
  [forevery effectgroups]
    <div class="effecttool__group">
      <div class="effecttool__group__header" data-effectgroupkey="[effectgroupkey]">
        <div class="effecttool__group__title">[title]</div>
      </div>

      [forevery effects]
        <div class="effecttool__effect wh-rtd" data-variantkey="[variantkey]">
          [if icon]<img class="effecttool__effect__icon" src="[icon]" alt="" />[/if]
          [if description]
            <div class="effecttool__effect__description">[description]</div>
          [/if]
          [if reminder]
            <button class="effecttool__effect__setreminder"
                    data-reminder-title="[reminder_title]"
                    data-reminder-description="[reminder_description]"
                    data-reminder-link="[reminder_link]"
                    ><img src="[toolimgroot]tool-p3d/icons/fa5-calendar-alt.regular.cropped.svg" /> Plaats een herinnering in mijn agenda</button>
          [/if]
        </div>
      [/forevery]
    </div>

    [! Content here is generated by Javascript (in displayResults()) !]
  [/forevery]
</div>
*/
/*
export function dummy()
{

}
*/
export function morphEffectGroupsIntoAccordeon(node)
{
  /*
  Normal tool DOM:

  div.effecttool__group
  div.effecttool__group__header
    div.effecttool__group__title
  div.effecttool__effect
    div.effecttool__effect__description
      h2.heading2
      p.normal
      ul.unordered

  Converted tool DOM (effectgroup accorderons):

  div.effecttool__group
  div.effecttool__group__header
    div.effecttool__group__title
  details.effecttool__effect
    summary.effecttool__effect__customtitle
    div.effecttool__effect__description
      (first h2 removed)
      p.normal
      ul.onundered

  Converted tool dom (effectgroups as accordeon):

  details.effecttool__group
    summary.effecttool__group__header
      effecttool__group__header__content (flex container for the number(before), title and expand-icon (after))
        effecttool__group__title
    div.effecttool__effect
      div.effecttool__effect__description
  */
  // let groups = document.querySelectorAll(".effecttool__group");

  //console.log("morphEffectGroupsIntoAccordeon");

  //let effectnodes = document.querySelectorAll(".effecttool__effect:not([hidden])");
  let effectgroups = node.querySelectorAll(".effecttool__groups .effecttool__group");

  for(let node of effectgroups)
  {
    //console.log("Converting Effectgroup", node);

    let header = node.querySelector(".effecttool__group__header");

    let title = header.textContent;
    let effectgroupkey = header.getAttribute("data-effectgroupkey");

    // Recreate effectgroup as <detail>
    // - .effecttool__group__header__content is needed because Chrome doesn't allow display: flex; on <summary>
    let neweffectgroup =
        <details class="effecttool__group">
          <summary class="effecttool__group__header" data-effectgroupkey={effectgroupkey}>
            <div class="effecttool__group__header__content">
              <div class="effecttool__group__title">{title}</div>
            </div>
          </summary>
          <div class="effecttool__group__content"></div>
        </details>;

    header.parentNode.removeChild(header);

    let contentnode = neweffectgroup.querySelector(".effecttool__group__content");
    // console.log("Adding content of old .effecttool__group node to the new element");

    // add all remaining content
    while(node.firstChild)
      contentnode.appendChild(node.firstChild);

    node.parentNode.replaceChild(neweffectgroup, node);

    // console.log("Accordeon item", newelem);
  }
}
