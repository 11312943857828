import * as pxl from "@mod-consilio/js/pxl";
import * as browser from 'dompack/extra/browser';

window._paq = window._paq || []; //we support matomo natively, so prep it

let ga_eventcategory;
let ga_queue = [];

export async function setupGA(trackerid, eventcategory, options)
{
  console.log("[ToolPlatform] Setting up GA \"%s\"", trackerid);

  options = { variantdimension: ""
            , ...options
            };

  // Google Analytics 4 property
  if (trackerid.substring(0,2) === "G-")
  {
    if (options.variantdimension != "")
      console.error("variantdimension not supported for Google Analytics 4.");

    console.info(`Initializing Google Analytics 4 with property-ID ${trackerid}`);

    // https://support.google.com/analytics/answer/10271001
    let script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackerid}`;
    script.setAttribute("async", "");
    //document.body.firstElementChild;
    document.body.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    window.gtag = gtag;
    gtag('js', new Date());
    // gtag('config', "3171916937");
    return;
  }


  //we can't be sure GA is already loaded, so just trigger it - TODO cooperate better and through a GTM tag ?
  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

  ga('create', trackerid, 'auto', 'eft')
  ga('eft.set', 'anonymizeIp', true);

  if (options.variantdimension != "")
  {
    // The Publisher "A/B test" file adds the variant tag as attribute to <html>
    let pagevariant = document.documentElement.getAttribute("data-experiment-variant");
    if (pagevariant && pagevariant != "")
      ga("eft.set", options.variantdimension, pagevariant);
  }

  ga('eft.set', 'page', location.href);

  ga_eventcategory = eventcategory;
  ga_queue.forEach(doGaEvent);
}

function doGaEvent(fields)
{
  if(fields.options && fields.options.sync && navigator.sendBeacon)
    ga('eft.set', 'transport', 'beacon');

  //console.info("GA!", ga);
  ga('eft.send', 'event', ga_eventcategory, fields.action, fields.label||'', (fields.options ? fields.options.value : 0) || 0);
}

export function reportNewEvent(action, label, options)
{
  if(window._paq) //TODO check if matomo is actually enabled, _paq always exists
  {
    // Send an event to Matamo (ex-Piwik)
    if(options && options.sync && navigator.sendBeacon) //TODO only for this statement? but generally sync: is used when we're about to exit anyway
      window._paq.push(['alwaysUseSendBeacon']);
    window._paq.push(['trackEvent', 'Effecttool', action, label||'' ]);
  }

  let todo_ga = {action,label,options};
  if(ga_eventcategory)
    doGaEvent(todo_ga);
  else
    ga_queue.push(todo_ga);

  console.log('reportNewEvent', action, label, options);
}

/*
Send event data to GA4 and PXL
*/
export function reportEvent(type, values, options)
{
  values = { ...values
//           , ds_theme:   formsettings.theme
//           , dn_version: formsettings.conceptmode ? 0 : formsettings.version
           // , ds_tool:    this.getToolId() //FIXME
           , ds_device:  browser.getTriplet()
           };

  // Push to Google Analytics 4
  if (window.gtag)
    window.gtag("event", type, values);

//FIXME conceptmode
  // if(!formsettings.conceptmode)
    pxl.sendPxlEvent("toolplatform:" + type, values, options);
  // else
    // console.log("Not sending pxl in concept mode:", {type, ...values});
}

export function setupPxl(formsettings)
{
  pxl.setPxlOptions({ recordurl: new URL("/.px/", formsettings.cdnroot || location.href).toString()
                    });
}

