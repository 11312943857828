let panelobserver = new ResizeObserver(resizePanels);


let update = 0;

function resizePanels(entries)
{
  // console.log("Resize observed", entries);

  // FIXME: better throtteling
  update++;
  if (update > 200)
    return;

  for (let entry of entries)
    drawSlopedPanel(entry.target, entry.target.querySelector(".qpanel__background"));
}


export function drawSlopedPanel(container, svgnode)
{
  //console.info("drawSlopedPanel", container, svgnode);

  let vector = getComputedStyle(document.documentElement).getPropertyValue("--slope-vector");
  let parts = vector.split("/");

  let createpanel = !svgnode;

  let containerbounds = container.getBoundingClientRect();

  let vectorx = 4;
  let vectory = -1;
  if (parts.length == 2)
  {
    vectorx = parseInt(parts[0]);
    vectory = parseInt(parts[1]);
  }
  // console.log(vectorx, vectory);

  let strokewidth = 6;
  let left = strokewidth / 2;
  let left_top = -vectory/vectorx * containerbounds.width + strokewidth / 2;

  let right = containerbounds.width - strokewidth / 2;
  let right_top = strokewidth / 2;

  let bottom_y = containerbounds.height - strokewidth / 2;

  let pathstring =
               `M0 ${left_top}
                L${right} ${right_top}
                ${right} ${bottom_y}
                ${left} ${bottom_y}
                ${left} ${left_top}
               `;

  //console.log(containerbounds);
  //console.log(pathstring);

  let path;

  if (!svgnode)
  {
    svgnode = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svgnode.setAttribute("width", "100%");
    svgnode.setAttribute("height", "100%");
    svgnode.setAttribute("preserveAspectRatio", "none");
    svgnode.setAttribute("class", "qpanel__background");

    path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    path.style.cssText = "vector-effect: non-scaling-stroke; fill: #22d97c; stroke: #000000; stroke-width: 6px;";

    svgnode.appendChild(path);
  }
  else
    path = svgnode.querySelector("path");

  svgnode.setAttribute("viewBox", `0 0 ${containerbounds.width} ${containerbounds.height}`);

  path.setAttribute("d", pathstring);

  if (createpanel)
  {
    container.insertBefore(svgnode, container.firstChild);
    panelobserver.observe(container);
  }

  return svgnode;
}
