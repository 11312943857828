/* DOM setup:

   - elements with class "wh-share" + attribute data-network automatically get share functionality
   - by default the title and URL of the current page is used for sharing
     (title can be read from <og:title> or <title>, but can also be overridden)
   - For the pinterest share, there has to be media to share.
     If not specified in a data-media attribute, the <og:image> will be used instead
   - the data-nohash attribute can be used to strip any hash from the page location
     (i.e. to prevent the location hash from being shared)

   Versions
   - v1.0 - designfiles
   - v1.1 - WIG? ability to override title and url using attributes
   - v1.2 - ability to set a text which will be used instead of url for networks which accept a text
            dompack.closest -> element.closest

   Basic usage:

     <span class="wh-share" data-network="<network>"></span>
     <span class="wh-share" data-network="<network>"></span>

   To use a specific share URL (instead of the current page URL)

     <div class="myclass"
          data-shareurl="https://www.webhare.nl/iwanttosharethisurl">
       <span class="wh-share" data-network="<network>"></span>
       <span class="wh-share" data-network="<network>"></span>
     </div>


   NOTE: Use wh-share, share without prefix is deprecated


   Settings:

   - data-sharetitle attribute
     To specify a title. You should only use this when linking to another URL (using data-shareurl) and
     the content you link to isn't a webpage. (examples of such content: PDF files, images, video files)

   - data-shareurl attribute
     When specified on the element with .wh-share or any parent,
     the specified URL will be shared. Otherwise the current page URL will be used.


   Supported networks:

   - email
   - facebook
   - linkedin
   - twitter
   - pinterest
   - print
*/

import * as dompack from "dompack";


function initShareButtons()
{
  document.body.addEventListener("click", doCheckForShare);
}

function doCheckForShare(evt)
{
  var sharenode = evt.target.closest(".wh-share");
  if (!sharenode)
    return;

  evt.preventDefault();

  var options =
      { networktype: sharenode.getAttribute("data-network")
      , nohash:      sharenode.hasAttribute("data-nohash")
      , title:       ""
      , title_explicit: false // if we have an explicitely set title, use it in a Facebook share as quote (usefull for links to non-webpage content, such as PDF files)
      , hashtag:     ""
      , link:        ""
      , text:        ""
      , appendurltotext: false // when an explicit text is specified, also append the link
      };

  var nodewithtitle = sharenode.closest("[data-sharetitle]");
  if (nodewithtitle)
  {
    options.title = nodewithtitle.getAttribute("data-sharetitle");
    options.title_explicit = true;
  }
  else
    options.title = getTitle(sharenode);

  let nodewithshareurl = sharenode.closest("[data-shareurl]");
  if (nodewithshareurl)
    options.link = nodewithshareurl.getAttribute("data-shareurl");

  let nodewithtext = sharenode.closest("[data-sharetext]");
  if (nodewithtext)
    options.text = nodewithtext.getAttribute("data-sharetext");

  if (sharenode.hasAttribute("data-appendurltotext"))
    options.appendurltotext = true;

  console.log(options);

  activatePageShare(options);
}

function activatePageShare(options)
{
  // FIXME: should we use:
  // let link = options.link || whintegration.config.obj.canonicalurl || location.href;

  var link = options.link || location.href;
  if (options.nohash)
    link = link.split("#")[0];


  //let descr_node = document.querySelector('meta[property="og:description"]');
  //let ogdescription = descr_node.getAttribute("content");

  let sharetext;
  if (options.text)
    sharetext = options.text;
  else
    sharetext = options.title;

  if (options.appendurltotext)
    sharetext += " " + link;


  console.log( "activatePageShare"
             , { options:   options
               , link:      link
               , sharetext: sharetext
               });


  switch (options.networktype)
  {
    case "facebook":
    {
      /*
      For a while (2012-2013?) Facebook listed this share URL as deprecated,
      but this notice was later removed.
      Facebook did however remove the ability to pass an title, summary and image through the share page.

      https://developers.facebook.com/support/bugs/357750474364812/
      */
      //var url = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(link);
      var url = "https://www.facebook.com/sharer.php?u=" + encodeURIComponent(link);

      // NOTE: quote is not officially listed in the documentation but works (jan 2020)
      if (options.title_explicit)
        url += "&quote=" + encodeURIComponent(options.title);

      // NOTE: hashtag is not officially listed in the documentation but works (jan 2020)
      if (options.hashtag)
        url += "&hashtag=" + encodeURIComponent("#" + options.hashtag);

      window.open(url
                , "wh_fbshare"
                , "width=845,height=347,menubar=no,location=no,status=no");
    } break;

    case "twitter":
    {
      // https://dev.twitter.com/web/tweet-button/web-intent

      var shareurl = "https://twitter.com/intent/tweet?url=" + encodeURIComponent(link);

      if (options.title != "")
        shareurl += "&text=" + encodeURIComponent(options.title);

      window.open(shareurl
                , "wh_twittershare"
                , "width=550,height=300,menubar=no,location=no,status=no");
    } break;

    case "linkedin":
    {
      // https://developer.linkedin.com/docs/share-on-linkedin

      var shareurl = "https://www.linkedin.com/shareArticle?url=" + encodeURIComponent(link);

      if (options.title != "")
        shareurl += "&title=" + encodeURIComponent(options.title);

      window.open(shareurl
                , "wh_linkedinshare"
                , "width=600,height=700,menubar=no,location=no,status=no");
    } break;

    case "pinterest":
    {
      // https://developers.pinterest.com/pin_it/

      var media = networkelement.getAttribute("data-media");
      if (!media)
      {
        media = document.head.querySelector('meta[property="og:image"]');
        if (media)
          media = media.getAttribute("content");
      }
      if (media)
      {
        var shareurl = "https://www.pinterest.com/pin/create/button/?url=" + encodeURIComponent(link) + "&media=" + encodeURIComponent(media);

        if (options.title != "")
          shareurl += "&description=" + encodeURIComponent(options.title);

        window.open(shareurl
                  , "wh_pinterestshare"
                  , "width=750,height=550,menubar=no,location=no,status=no");
      }
    } break;

    case "whatsapp":
    {
      // let shareurl = `https://api.whatsapp.com/send?text=${encodeURIComponent(ogdescription)}`;
      let shareurl = `https://api.whatsapp.com/send?text=${encodeURIComponent(sharetext)}`;
      window.open(shareurl);

// send to a specific phone number: https://wa.me/whatsappphonenumber/?text=urlencodedtext
/*
Use: https://wa.me/15551234567

Don't use: https://wa.me/+001-(555)1234567
*/

    } break;

    case "email":
    {
      // https://en.wikipedia.org/wiki/Mailto
      // http://www.ietf.org/rfc/rfc6068.txt

      let shareurl = "mailto:?body=" + encodeURIComponent(sharetext);

      if (options.title != "")
        shareurl += "&subject=" + encodeURIComponent(options.title);

      // Open in new tab, because GMail may be set as default mailed causing it to open in the current window
      window.open(shareurl
                , "wh_emailshare"
                , "width=750,height=550,menubar=no,location=no,status=no");

    } break;

    case "print":
    {
      window.print();
    } break;

    default:
    {
      console.error("No such network '" + options.networktype + "'");
    }
  }
}

function getTitle(networkelement)
{
  // find a title
  var title = "";

  // try <og:title>
  var ogtitle = document.head.querySelector('meta[property="og:title"]');
  if (ogtitle)
  {
    title = ogtitle.getAttribute("content");
  }
  else
  {
    // try <title>
    var titleattr = document.head.querySelector("title");
    if (titleattr)
      title = titleattr.innerText;
  }

  return title;
}

dompack.onDomReady(initShareButtons);

