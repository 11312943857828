import * as dompack from 'dompack';

import * as whintegration from '@mod-system/js/wh/integration';
import '@mod-system/js/wh/errorreporting'; //log JS errors to notice log

// import "@typopro/web-dosis/TypoPRO-Dosis.css";

import "./web/fonts/fontawesome5/css/all.css";///mixins.scss";
import '../shared/forms';
import './shared/rtd/rtd';
import './kenjebankzaken.scss';

//import './almere18.lang.json';

import "./pages/tool";

// open external links in new window - see https://code.webhare.com/publisher/utilities/linkhandler/
import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';
import * as drawingTools from "./shared/drawingtools.es";



openLinksInNewWindow({ extensions:    [ 'pdf', 'rss' ]
                    //, internalhosts: internalhosts
                     });


dompack.onDomReady(initBackground);

function initBackground()
{
  // Add the background box with sloped top
  let wrapper = document.querySelector(".contentwrapper");
  let background_svg = drawingTools.drawSlopedPanel(wrapper);
  console.info("Background", background_svg);
  background_svg.className = "qpanel__background";
  // wrapper.appendChild(background_svg);
  wrapper.insertBefore(background_svg, wrapper.firstChild);
}
